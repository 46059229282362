import PropTypes from "prop-types";

export default function IconAlertFilled({
	width, height, fill,
}) {

	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M1.66669 10.0001C1.66669 5.39771 5.39765 1.66675 10 1.66675C14.6024 1.66675 18.3334 5.39771 18.3334 10.0001C18.3334 14.6025 14.6024 18.3334 10 18.3334C5.39765 18.3334 1.66669 14.6025 1.66669 10.0001ZM10.6757 5.99608C10.6757 5.62291 10.3732 5.3204 10 5.3204C9.62685 5.3204 9.32434 5.62291 9.32434 5.99608V10.0001C9.32434 10.3732 9.62685 10.6758 10 10.6758C10.3732 10.6758 10.6757 10.3732 10.6757 10.0001V5.99608ZM10 13.3284C9.62685 13.3284 9.32434 13.6309 9.32434 14.0041C9.32434 14.3773 9.62685 14.6798 10 14.6798H10.01C10.3832 14.6798 10.6857 14.3773 10.6857 14.0041C10.6857 13.6309 10.3832 13.3284 10.01 13.3284H10Z" fill={fill} />
		</svg>

	);

}

IconAlertFilled.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	fill: PropTypes.string,
};

IconAlertFilled.defaultProps = {
	width: "20",
	height: "20",
	fill: "#FAAD14",
};
