import PropTypes from "prop-types";

const IconRadio = ({ active, height, width }) => {

	if (active) {

		return (
			<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
				<circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="6" fill="white" stroke="#1B8884" strokeWidth="4" />
			</svg>
		);

	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
			<circle cx="12" cy="12" r="7.25" fill="white" stroke="#DFE3E8" strokeWidth="1.5" />
		</svg>
	);

};

IconRadio.propTypes = {
	active: PropTypes.bool,
	height: PropTypes.string,
	width: PropTypes.string,
};

IconRadio.defaultProps = {
	active: false,
	height: "24",
	width: "24",
};

export default IconRadio;
