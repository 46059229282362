import PropTypes from "prop-types";

export default function IconCircleCheckFilled({
	width, height, fill,
}) {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill={fill}>
			<path fillRule="evenodd" clipRule="evenodd" d="M10 16.6639C13.6819 16.6639 16.6667 13.6791 16.6667 9.99723C16.6667 6.31533 13.6819 3.33057 10 3.33057C6.31814 3.33057 3.33337 6.31533 3.33337 9.99723C3.33337 13.6791 6.31814 16.6639 10 16.6639ZM13.7943 8.35584C14.0383 8.11176 14.0383 7.71604 13.7943 7.47196C13.5502 7.22788 13.1544 7.22788 12.9104 7.47196L9.18565 11.1967L7.73365 9.74469C7.48957 9.50061 7.09384 9.50061 6.84977 9.74469C6.60569 9.98876 6.60569 10.3845 6.84977 10.6286L8.7437 12.5225C8.98778 12.7666 9.38351 12.7666 9.62759 12.5225L13.7943 8.35584Z" fill={fill} />
		</svg>
	);

}

IconCircleCheckFilled.propTypes = {
	width: PropTypes.string,
	height: PropTypes.string,
	fill: PropTypes.string,
};

IconCircleCheckFilled.defaultProps = {
	width: "20",
	height: "20",
	fill: "#1B8884",
};
